// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-internal-tools-js": () => import("../src/pages/projects/internalTools.js" /* webpackChunkName: "component---src-pages-projects-internal-tools-js" */),
  "component---src-pages-projects-portfolio-2017-js": () => import("../src/pages/projects/portfolio2017.js" /* webpackChunkName: "component---src-pages-projects-portfolio-2017-js" */),
  "component---src-pages-projects-portfolio-2020-js": () => import("../src/pages/projects/portfolio2020.js" /* webpackChunkName: "component---src-pages-projects-portfolio-2020-js" */),
  "component---src-pages-projects-slug-search-js": () => import("../src/pages/projects/slugSearch.js" /* webpackChunkName: "component---src-pages-projects-slug-search-js" */),
  "component---src-pages-projects-stock-journal-js": () => import("../src/pages/projects/stockJournal.js" /* webpackChunkName: "component---src-pages-projects-stock-journal-js" */)
}

